import Vue from 'vue';
import vueRouter from 'vue-router';
Vue.use(vueRouter);

const routes = [{
		path: '/',
		redirect: '/HomePage',
	},
	{
		path: '/pages',
		name: 'PagesT',
		component: () => import('../pages/index.vue'),
		meta: {
			requireAuth: true,
			title: '首页'
		},
		children: [
			// 首页
			{
				path: '/HomePage',
				name: 'HomePage',
				component: () => import('../pages/home/HomePage/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			// 规格
			{
				path: '/Spec',
				name: 'Spec',
				component: () => import('../pages/home/Spec/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			// 商品列表
			{
				path: '/Goods',
				name: 'Goods',
				component: () => import('../pages/home/Goods/index.vue'),
				meta: {
					requireAuth: true
				},
			},
			// 添加商品
			{
				path: '/GoodsAdd',
				name: 'GoodsAdd',
				component: () => import('../components/GoodsAdd.vue'),
				meta: {
					requireAuth: true
				},
			},
			// 订单列表
			{
				path: '/Order',
				name: 'Order',
				component: () => import('../pages/home/Order/index.vue'),
			},
			// 退款列表
			{
				path: '/OrderRefund',
				name: 'OrderRefund',
				component: () => import('../pages/home/OrderRefund/index.vue'),
			},
			// 资金流水
			{
				path: '/CapitalFlow',
				name: 'CapitalFlow',
				component: () => import('../pages/home/CapitalFlow/index.vue'),
			},
			// 转账申请
			{
				path: '/TransferRequest',
				name: 'TransferRequest',
				component: () => import('../pages/home/TransferRequest/index.vue'),
			},
			// 财务设置
			{
				path: '/FinancialSettings',
				name: 'FinancialSettings',
				component: () => import('../pages/home/FinancialSettings/index.vue'),
			},
			// 商户设置
			{
				path: '/ShopSet',
				name: 'ShopSet',
				component: () => import('../pages/home/ShopSet/index.vue'),
			},
			// 管理员列表
			{
				path: '/AdministratorList',
				name: 'AdministratorList',
				component: () => import('../pages/home/AdministratorList/index.vue'),
			},
			// 小票打印
			{
				path: '/ReceiptPrinting',
				name: 'ReceiptPrinting',
				component: () => import('../pages/home/ReceiptPrinting/index.vue'),
			},
			// 退换货地址管理
			{
				path: '/ChangeAddress',
				name: 'ChangeAddress',
				component: () => import('../pages/home/ChangeAddress/index.vue'),
			},
			// 个人中心
			{
				path: '/UserInfo',
				name: 'UserInfo',
				component: () => import('../pages/home/UserInfo/index.vue'),
			},
		],
	},
	{
		path: '/login',
		name: 'loginT',
		component: () => import('../pages/login/index.vue'),
	},
];
const router = new vueRouter({
	routes,
});
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token');
	if (to.meta.requireAuth && !token) {
		next({
			name: 'loginT',
		});
	} else {
		next();
	}
});
export default router;